import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import React, { useEffect } from "react";
import Layout from "../components/layout";
import { BLOCKS } from "@contentful/rich-text-types";
import { DataProvider } from "../contentful/data-provider";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import Seo from "../components/seo";

function LandingPageSingle({ data }) {
	const landingPage = data.contentfulLandingPage;
	const dataProvider = new DataProvider(data);

	const options = {
		renderNode: {
			[BLOCKS.EMBEDDED_ASSET]: (node) => {
				const { gatsbyImageData, description, mimeType, url } = node.data.target;
				if (mimeType.includes("video")) {
					return <video src={url} muted playsInline loop={true} autoPlay={true} />;
				}
				return <GatsbyImage image={getImage(gatsbyImageData)} alt={description} />;
			},
		},
	};

	useEffect(() => {
		if (landingPage.renderCode) {
			let bodyString = "";
			try {
				bodyString = JSON.parse(landingPage.body.raw)
					.content[0].content[0].value.replace("/upload/", "https://old.glassview.com/upload/")
					.replaceAll('""', '"')
					.replaceAll("'", '"');
			} catch (e) {
				bodyString = "Render error...";
			}

			var postscribe = require("postscribe");
			postscribe("#content-block", bodyString);
		}
	}, []);

	const renderContent = () => {
		if (landingPage.renderCode) {
			return <div className={"landing-page-content"} id={"content-block"} />;
		}

		return <div className={"landing-page-content"}>{renderRichText(landingPage.body, options)}</div>;
	};

	return (
		<Layout dataProvider={dataProvider}>
			<Seo dataProvider={dataProvider} title={`GlassView | Work - ${landingPage.title}`} />
			<main className={"pt-24 lg:pt-24 pb-20"}>
				<section className='pb-10 md:pb-20'>
					<div className={"container text-center flex justify-center"}>{renderContent()}</div>
				</section>
			</main>
		</Layout>
	);
}

export default LandingPageSingle;

export const query = graphql`
	query LandingPageBySlug($slug: String!, $locale: String) {
		contentfulLandingPage(slug: { eq: $slug }) {
			id
			slug
			title
			body {
				raw
				references {
					contentful_id
					title
					description
					gatsbyImageData
					__typename
					mimeType
					url
				}
			}
			renderCode
		}
		allContentfulMenu(filter: { node_locale: { eq: $locale } }) {
			edges {
				node {
					...Menus
				}
			}
		}
	}
`;
